const state = {
    pageDetail: {}
}

const mutations = {
    SET_PAGE_DETAIL: (state, detail) => {
        state.pageDetail[detail.page] = detail.pageId;
    },
    DELETE_PAGE_DETAIL: (state, key) => {
        delete state.pageDetail[key];
    }
}

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
}
