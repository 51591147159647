const state = {
  // 审批流程所有节点
  nodeMap: new Map(),
  isEdit: null,
  selectedNode: {},
  selectFormItem: null,
  design:{},
  runningList: [],
  noTakeList: [],
  endList: [],
  diagramMode: 'design',
}

const mutations = {
  selectedNode(state, val) {
    state.selectedNode = val
  },
  loadForm(state, val){
    state.design = val
  },
  setIsEdit(state, val){
    state.isEdit = val
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
