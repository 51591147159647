/* eslint-disable */
import {loginByUsername, logout, getUserInfo, login, getMenu, getRoleRoutes, getRoleInfos} from '@/api/library/login'
import {getToken, setToken, removeToken} from '@/utils/auth'
import router, {resetRouter, errorRoute} from "@/router/index";
import {setPath} from "@/utils/asyncLoadView";
import watermark from "watermark-package";
import {dealCurrentUserInfo} from "@/utils/tools";
import store from "../index";

function handleData(item) {
    const localItem = {
        path: item.menuUrl,
        menuName: item.menuName,
    };
    return localItem;
}

function mapRoles(menus, rrr) {
    menus.forEach((item) => {
        let localItem = {};
        localItem = handleData(item);
        rrr.push(localItem);
        if (localItem) {
            if (item.children && item.children.length) {
                mapRoles(item.children, rrr);
            }
        }
    });
}

function mapMenus(menus) {
    const arrayOfSquares = menus.map((item) => {
        let localItem = {};
        localItem = handleData(item);
        if (localItem) {
            if (item.children && item.children.length) {
                localItem.children = mapMenus(item.children);
            }
            return localItem;
        }
        return false;
    });
    return arrayOfSquares;
}

function getSessionMenu() {
    return new Promise((resolve, reject) => {
        let ymenus = JSON.parse(localStorage.getItem("userMenu"));
        if (ymenus) {
            let obj = {
                data: ymenus,
            };
            resolve(obj);
        } else {
            reject();
        }
    });
}

function setLocation(menus) {
    localStorage.setItem("userMenu", JSON.stringify(menus));
    let operationMaintenance = [];
    let organizationalStructure = [];
    let ElectronicComponents = [];
    let ApprovalProcess = [];
    let RuleBaseManage = [];
    let BOMManage = [];
    let ProductStructure = [];
    let CodeRuleManagement = [];
    let CategoryManagement = [];
    let ManufacturerFactory = [];
    let MessageCenter = [];
    let SupplierFactory = [];
    let TheDataSource = [];
    let TheSymbolLibrary = [];
    let SymbolEncapsulation = [];
    let ProjectManagement = [];
    let FlowFormLibrary = [];
    let BuildingBusiness = [];
    let EncapsulationLibrary = [];
    let EDMCadencePlugIn = [];
    menus.forEach((v, i) => {
        if (v.remark == "operationMaintenance") {
            operationMaintenance.push(v);
        } else if (v.remark == "organizationalStructure") {
            organizationalStructure.push(v);
        } else if (v.remark == "ElectronicComponents") {
            ElectronicComponents.push(v);
        } else if (v.remark == "ApprovalProcess") {
            ApprovalProcess.push(v);
        } else if (v.remark == "RuleBaseManage") {
            RuleBaseManage.push(v);
        } else if (v.remark == "BOMManage") {
            BOMManage.push(v);
        } else if (v.remark == "ProductStructure") {
            ProductStructure.push(v);
        } else if (v.remark == "CodeRuleManagement") {
            CodeRuleManagement.push(v);
        } else if (v.remark == "CategoryManagement") {
            CategoryManagement.push(v);
        } else if (v.remark == "ManufacturerFactory") {
            ManufacturerFactory.push(v);
        } else if (v.remark == "MessageCenter") {
            MessageCenter.push(v);
        } else if (v.remark == "SupplierFactory") {
            SupplierFactory.push(v);
        } else if (v.remark == "TheDataSource") {
            TheDataSource.push(v);
        } else if (v.remark == "TheSymbolLibrary") {
            TheSymbolLibrary.push(v);
        } else if (v.remark == "SymbolEncapsulation") {
            SymbolEncapsulation.push(v);
        } else if (v.remark == "ProjectManagement") {
            ProjectManagement.push(v);
        } else if (v.remark == "FlowFormLibrary") {
            FlowFormLibrary.push(v);
        } else if (v.remark == "BuildingBusiness") {
            BuildingBusiness.push(v);
        } else if (v.remark == "EncapsulationLibrary") {
            EncapsulationLibrary.push(v);
        } else if (v.remark == "EDMCadencePlugIn") {
            EDMCadencePlugIn.push(v);
        }
    });
    localStorage.setItem("ApprovalProcess", JSON.stringify(ApprovalProcess));
    localStorage.setItem("BOMManage", JSON.stringify(BOMManage));
    localStorage.setItem("BuildingBusiness", JSON.stringify(BuildingBusiness));
    localStorage.setItem(
        "CategoryManagement",
        JSON.stringify(CategoryManagement)
    );
    localStorage.setItem(
        "CodeRuleManagement",
        JSON.stringify(CodeRuleManagement)
    );
    localStorage.setItem(
        "ElectronicComponents",
        JSON.stringify(ElectronicComponents)
    );
    localStorage.setItem(
        "EncapsulationLibrary",
        JSON.stringify(EncapsulationLibrary)
    );
    localStorage.setItem("FlowFormLibrary", JSON.stringify(FlowFormLibrary));
    localStorage.setItem(
        "ManufacturerFactory",
        JSON.stringify(ManufacturerFactory)
    );
    localStorage.setItem("MessageCenter", JSON.stringify(MessageCenter));
    localStorage.setItem(
        "operationMaintenance",
        JSON.stringify(operationMaintenance)
    );
    localStorage.setItem(
        "organizationalStructure",
        JSON.stringify(organizationalStructure)
    );
    localStorage.setItem("ProductStructure", JSON.stringify(ProductStructure));
    localStorage.setItem("ProjectManagement", JSON.stringify(ProjectManagement));
    localStorage.setItem("RuleBaseManage", JSON.stringify(RuleBaseManage));
    localStorage.setItem("SupplierFactory", JSON.stringify(SupplierFactory));
    localStorage.setItem(
        "SymbolEncapsulation",
        JSON.stringify(SymbolEncapsulation)
    );
    localStorage.setItem("TheDataSource", JSON.stringify(TheDataSource));
    localStorage.setItem("TheSymbolLibrary", JSON.stringify(TheSymbolLibrary));
    localStorage.setItem("EDMCadencePlugIn", JSON.stringify(EDMCadencePlugIn));
}

export function getUserMenu() {
    return new Promise((resolve, reject) => {
        getRoleRoutes({}).then(response => {
            if (response.httpCode == 200) {
                let menus = response.data;
                if (!menus) {
                    reject('未获取到菜单');
                    return false;
                }
                menus.forEach(v => {
                    if (v.parentId == '0') {
                        if (v.children && v.children.length > 0) {
                            v.children.forEach(it => {
                                it.component = "layout/library/index";
                            })
                        }
                    }
                })
                let arr = JSON.parse(JSON.stringify(menus));
                setPath(arr);
                arr.forEach(v => {
                    router.addRoutes([v]);
                    router.options.routes.push(v);
                })
                // 404
                router.addRoutes(errorRoute);
                router.options.routes.push(...errorRoute);
                // 获取按钮权限
                getRoleInfos({}).then(res => {
                    let buttonPermission = res.data.permissions;
                    if (buttonPermission) {
                        localStorage.setItem("buttonPermission", JSON.stringify(buttonPermission));
                    }
                    resolve(menus);
                })
            }
        }).catch(error => {
            reject(error)
        })
    })
}

const user = {
    namespaced: true,
    state: {
        user: '',
        userDetail: {},
        status: '',
        token: getToken(),
        name: '',
        roles: [],
        setting: {
            articlePlatform: []
        },
        activeModel: "",
        edmRoutes: [],
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_ACTIVE_MODEL: (state, activeModel) => {
            state.activeModel = activeModel;
        },
        SET_SETTING: (state, setting) => {
            state.setting = setting
        },
        SET_STATUS: (state, status) => {
            state.status = status
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_USET_DETAIL: (state, userDetail) => {
            state.userDetail = userDetail
        },
        SET_ROUTES: (state, routes) => {
            state.edmRoutes = routes;
            localStorage.setItem("edmRoutes", JSON.stringify(routes));
        },
    },
    actions: {
        // 用户名登录
        LoginByUsername({commit}, userInfo) {
            const username = userInfo.username.trim()
            return new Promise((resolve, reject) => {
                loginByUsername(username, userInfo.password).then(response => {
                    const data = response.data
                    commit('SET_TOKEN', data.token)
                    setToken(response.data.token)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 获取用户信息
        GetUserInfo({commit, state}) {
            return new Promise((resolve, reject) => {
                getUserInfo({}).then(response => {
                    if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
                        reject('error')
                    }
                    const data = response.data;
                    const currentUserInfo = dealCurrentUserInfo(data);
                    commit('SET_NAME', data.userName);
                    commit('SET_USET_DETAIL', data);
                    localStorage.setItem('userInfo', JSON.stringify(data));
                    sessionStorage.setItem("user", JSON.stringify(currentUserInfo));
                    // 水印
                    watermark.setWaterMark({
                        w_texts: [data.userName, data.account],
                        w_options: {
                            w_width: 520,
                            w_height: 230,
                            w_top: '0px',
                            w_left: '0px',
                            w_rotateDeg: 25,
                            w_font: '1.2rem Vedana',
                            w_color: '#666',
                            w_opacity: '0.2',
                            w_zIndex: '100000',
                        }
                    })
                    // 移除水印
                    // watermark.removeWatermark()
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        SetAppLogin({commit, state}, loginItem) {
            return new Promise((resolve, reject) => {
                getUserMenu().then(menus => {
                    const data = {
                        token: 'manger'
                    }
                    setToken(data.token)
                    commit('SET_ROUTES', menus);
                    commit('SET_TOKEN', data.token);
                    resolve()
                }).catch(err => {
                    reject(err);
                })
            })
        },
        // 登出
        LogOut({commit, state}) {
            return new Promise((resolve, reject) => {
                logout({}).then(() => {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                    removeToken();
                    resetRouter();
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 前端 登出
        FedLogOut({commit}) {
            return new Promise(resolve => {
                commit('SET_TOKEN', '')
                removeToken()
                resolve()
            })
        },
        Login({commit}, userInfo) {
            return new Promise((resolve, reject) => {
                login(userInfo).then(response => {
                    switch (response.httpCode) {
                        case 303:
                            alert(response.msg)
                            this.loading = false;
                            break;
                        default:
                            getUserMenu().then(menus => {
                                const data = {
                                    token: 'manger'
                                }
                                setToken(data.token)
                                commit('SET_ROUTES', menus);
                                commit('SET_TOKEN', data.token);
                                resolve()
                            }).catch(err => {
                                reject(err);
                            })
                            break;
                    }
                }).catch(error => {
                    reject(error)
                });
            });
        },
        appLogin({commit}, token) {
            return new Promise((resolve, reject) => {
                appUserGetMenu({}, token).then(response => {
                    if (response.httpCode == 200) {
                        let menus = response.data;
                        if (menus.length == 0) {
                            reject('未获取到菜单');
                            return false;
                        }
                        setLocation(menus)
                        setToken(token)
                        commit('SET_TOKEN', token);
                        resolve()
                    } else {
                        reject("登录失败")
                    }
                }).catch(error => {
                    reject(error)
                });
            });
        },
        getUserRoles({commit, state}) {
            return new Promise((resolve, reject) => {
                getSessionMenu()
                    .then((response) => {
                        const data = response.data;
                        if (data.length == 0) {
                            reject("未获取到菜单信息");
                            return false;
                        }
                        // 处理所有菜单，用户核对路由表进行挂载
                        const menus = mapMenus(data);
                        let rrr = [];
                        mapRoles(data, rrr);
                        commit("SET_ROLES", rrr);
                        resolve(rrr);
                    })
                    .catch((error) => {
                        removeToken();
                        resolve();
                    });
            });
        },
        setActiveModel({commit}, activeModel) {
            return new Promise(resolve => {
                commit('SET_ACTIVE_MODEL', activeModel)
                resolve()
            })
        },
    }
}

export default user
