import Layout from "@/views/layout/library/index";

const process = [
    {
        path: '/process/design/:code?/:type?',
        component: Layout,
        redirect: '/process/design/:code?/:type?',
        name: '表单流程设计',
        menuName: '表单流程设计',
        children: [
            {
                path: "/process/design/:code?/:type?",
                name: "design",
                component: () => import("@/views/workflow/processEngine/processScheme/process/FormProcessDesign"),
                meta: {title: '表单流程设计'}
            },
        ]
    },
    {
        path: '/process/instance/tabs/:processInstanceId/:taskId?/:isEdit',
        component: Layout,
        redirect: '/process/instance/tabs/:processInstanceId/:taskId?/:isEdit',
        name: 'instanceTab',
        menuName: '待办处理',
        children: [
            {
                path: "/process/instance/tabs/:processInstanceId/:taskId?/:isEdit",
                name: "design",
                component: () => import("@/views/workflow/processEngine/myToDo/process/ProcessInstanceTabs"),
                meta: {title: '待办处理'}
            },
        ]
    }
    ]


export default process;
