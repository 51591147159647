const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  edmRoutes: state => state.user.edmRoutes,
  introduction: state => state.user.introduction,
  status: state => state.user.status,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  permission_routers: state => state.permission.routers,
  permission_addRoutes: state => state.permission.addRoutes,
  addRouters: state => state.permission.addRouters,
  userDetail: state => state.user.userDetail,
  category: state => state.electronic.category,
  categoryIndex: state => state.electronic.categoryIndex,
  activeModel:state=>state.user.activeModel,
  screen:state=>state.app.screen,
  configInfo: state => state.myData.configInfo,
  workspace: state => state.myData.workspace,
  edmHeader: state => state.app.edmHeader,
  navigationList: state => state.app.navigationList,
  dialogStatus:state => state.app.dialogStatus,
  pageDetail:state => state.pageDetail.pageDetail,
}
export default getters;
