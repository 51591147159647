import Layout from "@/views/layout/library/index";

const teamWork = [
    {
        path: "/product/detail/:isDetail/:id",
        name: '板卡详情',
        menuName: '板卡详情',
        component: () => import("@/views/psa/teamWork/productManage/index"),
    },
    {
        path: "/document/detail/:isDetail/:id",
        name: '文档详情',
        menuName: '文档详情',
        component: () => import("@/views/psa/teamWork/documentManage/index"),
    },
    {
        path: "/software/detail/:isDetail/:id",
        name: '软件详情',
        menuName: '软件详情',
        component: () => import("@/views/psa/teamWork/softwareManage/index"),
    },
]


export default teamWork;
