import { readMyRoleBtnList} from '@/api/library/login'
const fs = require("fs");
var path = require('path');
const myData = {
  namespaced: true,
  state: {
    configInfo:{},
  },
  mutations: {
    SET_configInfo: (state, configInfo) => {
      state.configInfo = configInfo
    },
  },
  actions: {
    getConfigInfo({ commit }, data) {
      return new Promise((resolve,reject) => {
        //本地配置文件存放路径 , 开发和生产环境不同
        let configpath = path.join(data.exePath,'/config/config.json')
        fs.readFile(configpath, "utf-8", function(error, filedatas) {
          if (error) {
            commit('SET_configInfo', {})
          } else{
            let obj = JSON.parse(filedatas);
            commit('SET_configInfo', obj);
          }
        });
      })
    },
  }
}
export default myData
