import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
import {exitApp} from '@/utils/auth'
// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_URL, // api的base_url
    timeout: 15 * 1000, // request timeout
})
let currentMes = null

// request interceptor
service.interceptors.request.use(config => {
    /*if (store.getters.token) {
        config.headers['X-Token'] = getToken()
    }*/
    //--------- PartsLoader 让每个请求携带'cToken'-----------------
    if (!(window.deehow == undefined)) {
        config.headers['cToken'] = window.deehow.token();
    }
    return config;
}, error => {
    Promise.reject(error);
})

// response interceptor
service.interceptors.response.use(
    /**
     * 下面的注释为通过在response里，自定义code来标示请求状态
     * 当code返回如下情况则说明权限有问题，登出并返回到登录页
     * 如想通过xmlhttprequest来状态码标识 逻辑可写在下面error中
     * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
     */
    response => {
        const res = response.data;
        if (response.headers["content-type"] == "application/vnd.ms-excel;charset=UTF-8") {
            let dis = response.headers["content-disposition"];
            let arr = dis.split("filename=");
            if (arr.length !== 2) {
                alert("文件名称错误");
                return false;
            }
            let name = arr[1].slice(1, arr[1].length - 1)
            const blobf = new Blob([res], {type: response.headers["content-type"]});
            if ('download' in document.createElement('a')) {
                let url = window.URL.createObjectURL(blobf);
                // 生成一个a标签
                let link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                // 生成时间戳
                link.download = name;
                document.body.appendChild(link);
                link.click();
            } else {
                navigator.msSaveBlob(blobf, name);
            }
            return response;
        }
        if (res['httpCode']) {
            if (res.httpCode !== 200) {
                if (res.httpCode == 401) {
                    if (window.deehow != undefined) {
                        window.deehow.exec("Login", "");
                        return false;
                    }
                    MessageBox.confirm('您登录凭证失效,请重新登录', '提示', {
                        confirmButtonText: '重新登录',
                        type: 'warning',
                        closeOnClickModal: false,
                        showCancelButton: false,
                        showClose: false,
                        closeOnPressEscape:false,
                        closeOnHashChange:false,
                    }).then(() => {
                        exitApp();
                    })
                    return Promise.reject(false);
                } else if (res.httpCode == "-9999") {
                    window.location.href = location.protocol + "//" + location.host + "/license/license.html?msg=" + res.msg;
                } else if (res.httpCode == "409") {
                    if (res.msg.indexOf("已经登录") != -1) {
                        // exitApp()
                        return Promise.reject(response.data);
                    }
                } else if (res.httpCode > 500) {
                    return Promise.reject(response.data);
                }
                if (res.msg) {
                    if (res.msg.includes('符号库中存在符号') && res.httpCode === 400) {

                    } else {
                        if (currentMes) {
                            currentMes.close()
                        }
                        currentMes = Message({
                            showClose: true,
                            message: res.msg,
                            type: 'error',
                            duration: 3 * 1000
                        })
                    }
                }
                return Promise.reject(response.data);
            } else {
                return response.data;
            }
        } else {
            return response.data;
        }
    },
    error => {
        if (error.toString().includes('timeout of')) {
            if (currentMes) {
                currentMes.close()
            }
            currentMes = Message({
                message: '请求超时',
                type: 'error',
                duration: 3 * 1000,
            })
            return Promise.reject(error);
        }
        switch (error?.response?.status) {
            case 404:
                currentMes = Message({
                    message: '系统接口404',
                    type: 'error',
                    duration: 3 * 1000,
                })
                break;
            case 413:
                currentMes = Message({
                    message: '请求体过大',
                    type: 'error',
                    duration: 3 * 1000,
                })
                break;
            case 502:
                currentMes = Message({
                    message: '服务正在发布中，请稍后重试',
                    type: 'error',
                    duration: 3 * 1000,
                })
                break;
            default:
                currentMes = Message({
                    message: '服务异常',
                    type: 'error',
                    duration: 3 * 1000,
                })
        }
        return Promise.reject(error);
    })

export default service;
