// 动态加载 路由
function loadView(view) {
    return (resolve) => require([`@/views/${view}.vue`], resolve)
}

export function setPath(menus) {
    menus.forEach(v => {
        if (v.parentId === '0') {
            v.component = () => import('@/views/layout/edmIndexLay');
            if (v.path[0] !== '/') {
                v.path = '/' + v.path;
            }
            if (v.children && v.children.length === 0) {
                //
            } else {
                setPath(v.children);
            }
        } else {
            if (v.children && v.children.length === 0) {
                if (v.component !== '') {
                    v.component = loadView(v.component);
                } else {
                    delete v.component;
                }
            } else {
                v.component = loadView(v.component);
                setPath(v.children);
            }
            if (v.path[0] !== '/') {
                v.path = '/' + v.path;
            }
        }
    })
}
