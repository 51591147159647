/* eslint-disable */
function dealWithRole(roleList,addRouteByLogin){
  let accessedRouters = [];
  try{
    accessedRouters = filterRouter(addRouteByLogin, roleList);
    redirectPath(accessedRouters,"")
  }catch(err){
  }

  return accessedRouters;
}


/**
 * 比较路由是否在后台的权限表里面,返回true表示有权限，保留路由表中的该项，否则删除该项路由表
 * @param {*} path 当前路由的路径
 * @param {*} name 路由的名称
 */
function compareRoles(path, menuName, roles) {

  if (path !== '*') {
    return roles.some( role => role.path === path && role.menuName === menuName)
  } else {
    return true
  }
}
/**
 * 重定向
 * @param {*} arr 路由数组
 * @param {*} basUrl 路由的路劲(父)
 */
function redirectPath(arr,basUrl){
  arr.forEach((val)=>{
    if(val.path!=="*"){
      let str;
      if(val.path.indexOf("/") == 0){
        str = basUrl+val.path;
      }else{
        str = basUrl+ "/" +val.path;
      }

      if(val.children&&val.children.length>0&& val.children[0]){
        str += "/"+val.children[0].path;
        val.redirect = str;
        redirectPath(val.children,basUrl+val.path)
      }

    }
  })
}
function filterRouter(asyncRouterMap, roles) {

  let accessedRouters = asyncRouterMap.filter(route => {
    if (compareRoles(route.path, route.menuName, roles)) {
      if (route.children && route.children.length) {
        route.children = filterRouter(route.children, roles)
      }
      return true
    }
    return false
  })
  return accessedRouters
}
const state = {
  routes: [],
  addRoutes: [],
}

const mutations = {
  SET_ROUTES: (state, routes ) => {
    state.addRoutes = routes
    state.routes = routes
  }
}

const actions = {
  generateRoutes({ commit }, {roles,addRouteByLogin}) {
    return new Promise((resolve,reject) => {
      let dealWidthOver = dealWithRole(roles,addRouteByLogin);
      if(dealWidthOver.length<1){
        reject({err:"无权限",status:"no-role"})
      }
      commit('SET_ROUTES', dealWidthOver);
      resolve(dealWidthOver);
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
