<template>
  <!-- 总 layout -->
  <div>
    <el-header>
      <Header></Header>
    </el-header>
    <el-container>
      <el-aside width="collapse">
        <el-menu
          ref="menu"
          :default-active="activeMenu"
          router
          :collapse="isCollapse"
          unique-opened
          class="el-menu-vertical"
          background-color="#fff"
          text-color="#555"
          active-text-color="#333"
        >
          <el-submenu v-for="(item, index) in delMenus" :key="item.id" :index="item.id">
            <template slot="title">
              <img class="item-img" :src="require(`../../../assets/img/layout/${item.meta.icon}.png`)">
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item
                v-for="(it, i) in item.children"
                :key="it.id"
                :index="it.path"
                @click="handleSaveNav(item,it)"
            >
              {{ it.name }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main style="position: relative;">
          <!-- 导航 这里加 -->
          <navigation-bar v-if="showNavigationBar($route.path)"></navigation-bar>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from "@/components/sitePage/Header";
import navigationBar from "@/components/sitePage/NavigationBar";
import { mapGetters } from "vuex";
import {compareArr,findRootPathObjByCurrentPath} from "@/utils/tools";

export default {
  name: "index",
  components: { Header, navigationBar },
  data() {
    return {
      delMenus: [],
      showList: [
        "/psa",
        "/workflow"
      ],
    };
  },
  computed: {
    ...mapGetters(["userDetail", "edmRoutes", "sidebar", "edmHeader"]),
    isCollapse() {
      return !this.sidebar.opened;
    },
    activeMenu() {
      return this.$route.path;
    },
    showNavigationBar() {
      return function (path) {
        const tar = findRootPathObjByCurrentPath(path.split("/")[1]);
        if (!tar) return false;
        const tarPath = tar.path || "";
        return !!this.showList.find(p => p === tarPath);
      }
    }
  },
  methods: {
    initScreen() {
      let screenObj = {};
      screenObj.width = document.documentElement.clientWidth; //窗口宽度
      screenObj.height = document.documentElement.clientHeight; //窗口高度
      this.$store.dispatch("app/setScreen", screenObj);
      window.addEventListener("resize", () => {
        screenObj.width = document.documentElement.clientWidth; //窗口宽度
        screenObj.height = document.documentElement.clientHeight; //窗口高度
        this.$store.dispatch("app/setScreen", screenObj);
      });
    },
    getMenus(val) {
      let item = this.edmRoutes.find((v) => v.name == val);
      let fn = (arr) => {
        // 菜单排序
        arr = compareArr(arr,"sortNo");
        arr.forEach((v) => {
          if (v.path[0] !== "/") {
            v.path = "/" + v.path;
          }
          if (v.children.length > 0) {
            fn(v.children);
          }
        });
      };
      if(item && item.children) {
        fn(item.children);
        this.delMenus = item.children;
      }
    },
    handleSaveNav(parent,to) {
      let obj = {
        name: to.name,
        url: to.path,
        isAct: true,
        parentId:parent.parentId
      };
      if (obj.name && obj.name.indexOf("login") <= -1) {
        this.$store.dispatch("app/setNavigation", obj);
      }
    },
  },
  watch: {
    "edmHeader.nowTab": function(val) {
      this.getMenus(val);
    },
    activeMenu: function(val) {
      // 不高亮问题调试
      // console.log(this.$refs.menu.activeIndex,this.activeMenu);
    },
  },
  mounted() {
    this.initScreen();
    let val = this.edmRoutes[0].name;
    if (this.edmHeader.nowTab) {
      val = this.edmHeader.nowTab;
    }
    this.getMenus(val);
  },
};
</script>

<style>
.el-submenu__title {
  /*font-size: unset;*/
}
.el-submenu__title:hover {
  background-color: #e8f4ff !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.item-img {
  margin-right: 5px;
  width: 16px;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: $navbarHeight;
  padding: 0 !important;
  height: $navbarHeight !important;
}

.el-aside {
  background-color: #fff;
  color: #333;
  height: calc(100vh - #{$navbarHeight});
  border-right: 4px solid $bgaColor;
}
.el-menu {
  border-right: none;
  //font-size: unset !important;
}

.el-menu-item {
  //font-size: 13px;
}
.el-menu-vertical {
  //font-size: unset !important;
}

.is-active {
  background: #e8f4ff !important;
  color: #1890ff !important;
}
.sidebar-container {
  & .is-active {
    background-color: #e8f4ff !important;
    color: #1890ff !important;
  }
}

.nest-menu .el-submenu > .el-submenu__title,
.el-menu-item {
  //font-size:12px;
  color: #666;
  &:hover {
    background-color: #e8f4ff !important;
  }
}

.el-aside::-webkit-scrollbar {
  display: none;
}

.el-main {
  color: #333;
  padding: 0 !important;
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 200px;
}
.box_bgd {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 54px;
}
.box_bgd i {
  font-size: 20px;
}
.box_bgd:hover {
  background-color: #eaedf1;
}
</style>
