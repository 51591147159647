import Vue from "vue";
import store from "../store";

const HAS_PER = Vue.directive("has",{
    inserted:function (el,binding,vnode) {
        if (!Vue.prototype.$HAS(binding.value)) {
            el.remove();
        }
    }
})

Vue.prototype.$HAS = function (value) {
    let isHas = false;
    let buttonPermission = JSON.parse(localStorage.getItem("buttonPermission"));
    if (buttonPermission === undefined || buttonPermission === null || buttonPermission === "") {
        return false;
    }
    try {
        let targetArr = value.split(",");
        if (targetArr.length === 1) {
            if (buttonPermission.includes(value)) {
                isHas = true;
            }
        } else {
            // 模块电路、元器件库公用一个按钮时控制如下 v-has="cms按钮,cbb按钮"
            const cmsButton = targetArr.find(v => v.includes("library"));
            const cbbButton = targetArr.find(v => v.includes("circuits"));
            if (cmsButton && store.getters.edmHeader.partition === "cms" && buttonPermission.includes(cmsButton)) {
                isHas = true;
            } else if (cbbButton && store.getters.edmHeader.partition === "cbb" && buttonPermission.includes(cbbButton)) {
                isHas = true;
            }
        }
        return isHas;
    } catch(err) {
        console.log('权限按钮出错了',err);
        isHas = false;
        return isHas;
    }
}

export {
    HAS_PER,
}
