const state = {
  routes: [],
  addRoutes: [],
}

const mutations = {
  SET_ROUTES: (state, routes ) => {
    state.addRoutes = routes
    state.routes = routes
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
