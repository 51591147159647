import Vue from "vue";
import Router from "vue-router";
import store from '../store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {getToken} from '@/utils/auth' // getToken from cookie
import {findRootPathObjByCurrentPath} from "@/utils/tools";
function dealPartition(path) {
  let partition;
  switch (path) {
    case "/library":
      partition = "cms";
      break;
    case "/circuits":
      partition = "cbb";
      break;
    default:
      partition = "cms";
  }
  return partition;
}

NProgress.configure({
  showSpinner: false
})
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
Vue.use(Router);
export const errorRoute = [
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
];

import home from './modules/home';
import process from "./modules/processDesign";
import teamWork from "./modules/teamWork";
import boardBom from "./modules/boardBom";
import oaRouter from "./modules/oaRouter";

// 固定的路由
export const constantRoutes = [
  {
    path: '/redirect',
    hidden: true,
    meta: {
      noCache: true,
      login:"no"
    },
    children: [{
      path: '/redirect/:path*',
      component: () => import('@/components/redirect/index')
    }]
  },
  ...home,
  {
    path: "/404",
    name: "404",
    meta: {
      noCache: true,
      login:"no"
    },
    component: () => import("@/views/errorPage/404"),
    hidden: true,
  },
  {
    path: "/clientLogin",
    name: "clientLogin",
    meta: {
      login:"no"
    },
    component: () => import("@/components/redirect/clientLogin"),
    hidden: true,
  },
  {
    path: "/OALogin",
    name: "OALogin",
    meta: {
      login:"no"
    },
    component: () => import("@/components/redirect/oaLogin"),
    hidden: true,
  },
  ...process,
  ...teamWork,
  ...boardBom,
  ...oaRouter
];
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
const router = createRouter()

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (!sessionStorage.getItem("loginCase")) {
    sessionStorage.setItem("loginCase", to.path);
  }
  if(to.path.includes('/logHome') || to.path == '/login/logHome'){
    if (getToken()) {
      next("/");
      NProgress.done();
    } else {
      // 跳出循环
      next()
      NProgress.done();
    }
  }else{
    if (getToken() || to.meta.login === "no"){
      next();
      NProgress.done();
    } else {
      // 否则全部重定向到登录页
      next('/login/logHome');
      NProgress.done();
    }
  }
})

router.afterEach((to, from) => {
  const obj = findRootPathObjByCurrentPath(to.path.slice(1));
  if (obj) {
    store.dispatch("app/setSource", {
      source: 'edm',
      nowTab: obj.name,
      partition:dealPartition(obj.path)
    });
  }
  let tab = {
    name:to.name,
    url:to.path,
    isAct:true,
  }
  document.title = tab.name || "";
  store.getters.navigationList.forEach(v => v.isAct = false);
  if (tab.name && store.getters.navigationList.find(u => u.url === to.path)) {
    store.dispatch("app/setNavigation",tab);
  }
  NProgress.done();
})

export default router
