import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import router from './router';
import store from "./store/index";
import '@/assets/scss/index.scss';
import '@/utils/initJq.js';
// import "@/utils/permission";
import {setPath} from "@/utils/asyncLoadView";
import {getToken} from '@/utils/auth'
// 响应式布局样式
import 'element-ui/lib/theme-chalk/display.css';
import constants from "@/utils/constants";
Vue.prototype.$constants = constants;
// 矢量图标
import "@/assets/iconfont/iconfont.css"
import "@/assets/scss/workflowDesign.css";

// checkbox拖动调整顺序;
import VueDND from 'awe-dnd';

Vue.use(VueDND);

// 按钮权限指令
import HAS_PER from "@/utils/buttonPermission";

// 自定义方法
import '@/utils/CustomUtil';

// echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

// el-table表头
let rowClass = {background: '#f6f7fa', color: '#000000',fontWeight:'normal',padding: '4px',height:'37px'};
Vue.prototype.$rowClass = rowClass;
// el-table表格高度
let cellStyle = {padding:"2px 0"};
Vue.prototype.$cellStyle = cellStyle;
// el-table表格行高度
let rowStyle = {height:"37px"};
Vue.prototype.$rowStyle = rowStyle;

Vue.use(ElementUI, {
    size: 'small'
});

// 全局过滤器
import * as filters from './filters';
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

import components from "./components";
import Ellipsis from '@/components/workflow/processEngine/common/Ellipsis';
import WDialog from '@/components/workflow/processEngine/common/WDialog';
import Tip from '@/components/workflow/processEngine/common/Tip';
Vue.use(Ellipsis);
Vue.use(WDialog);
Vue.use(Tip);
Vue.use(components);

// 全局指令
import * as directives from "./directives";
Object.keys(directives).forEach(key => {
    Vue.directive(key, directives[key]);
})

/**
 * 分片上传
 */
import uploader from "vue-simple-uploader";
Vue.use(uploader);

Vue.prototype.bus = new Vue();
Vue.prototype.DEEHOWBASEURL = location.protocol + "//" + location.host;

// 判空方法
Vue.prototype.$isNotEmpty = function(obj){
    return (obj !== undefined && obj !== null && obj !== '' && obj !== 'null')
}
Vue.prototype.$getDefalut = function(obj, key, df){
    return (obj === undefined || key === undefined || !this.$isNotEmpty(obj[key])) ? df : obj[key];
}
// 深拷贝
Vue.prototype.$deepCopy = function (obj){return JSON.parse(JSON.stringify(obj))};
// 处理文件名截取后缀.前
Vue.prototype.$dealFileName = function (fileName){return fileName.split('.').slice(0, -1).join('.')};
// 获取文件后缀
Vue.prototype.$getFileSuffix = function (fileName) {return fileName.substring(fileName.lastIndexOf(".") + 1)};

// 动态路由挂载
function initRoute() {
    let edmRoutes = JSON.parse(localStorage.getItem("edmRoutes"));
    if (getToken() && edmRoutes && edmRoutes[0]) {
        let arr = [].concat(edmRoutes);
        store.commit("user/SET_ROUTES",arr);
        setPath(edmRoutes);
        edmRoutes.forEach(v => {
            router.addRoutes([v]);
            router.options.routes.push(v);
        })
        // 404
        const errorRoute = [
            {
                path: '*',
                redirect: '/404',
                hidden: true
            }
        ];
        router.addRoutes(errorRoute);
        router.options.routes.push(...errorRoute);
    }
}

const app = new Vue({
    router,
    store,
    render: h => h(App),
    data: {
        eventHub: new Vue()
    }
})

async function mountApp() {
    await initRoute()
    app.$mount('#app')
}
mountApp()
