import Layout from "@/views/layout/library/index";

const boardBom = [
    {
        path: 'boardCategoryDetail',
        component: () => import('@/components/library/boardcardBom/boardCategoryDetail'),
        name: 'BOM详情展示',
        icon: 'zonghe'
    },
    {
        path: 'boardCategoryUpload',
        component: () => import('@/components/library/boardcardBom/boardCategoryUpload'),
        name: 'BOM上传分析',
        icon: 'zonghe'
    },
    {
        path: 'bomHistory',
        component: () => import('@/components/library/boardcardBom/bomHistory'),
        name: 'BOM历史记录',
        icon: 'zonghe'
    },
    {
        path: 'bomVersion',
        component: () => import('@/components/library/boardcardBom/bomVersion'),
        name: 'BOM版本记录',
        icon: 'zonghe'
    },
    {
        path: 'bomOtherHistory',
        component: () => import('@/components/library/boardcardBom/bomOtherHistory'),
        name: '附属文件历史记录',
        icon: 'zonghe'
    },
];

export default boardBom;
